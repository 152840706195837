import React, { useState } from 'react';
import { createUseStyles } from 'react-jss'

// 
// ───  IMPORT DES ELEMENTS REACT MATERIAL UI ───────────────────────────────────────
//
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import TablePagination from '@material-ui/core/TablePagination';

// 
// ───  IMPORT DES ELEMENTS FONTAWESOME ───────────────────────────────────────
//
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faPencil, faSearch } from '@fortawesome/pro-solid-svg-icons'

const TableList = (props) => {

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // 
  // ───  DEFINITION DES STYLES DU COMPOSANT ───────────────────────────────────────
  //
  const useStyle = createUseStyles({
    container: {
      borderRadius: "1rem !important",
      boxShadow: "0 20px 27px 0 rgba(0, 0, 0, 0.05) !important",
    },
    edit: {
      marginRight: 10
    },
    icon: {
      cursor: "pointer"
    },
    row: {
      padding: '7px !important'
    }
  })

  const classes = useStyle()

  let data = props.pagination ? props.data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : props.data

  return (
    <>
      <TableContainer component={Paper} className={classes.container}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              {props.header.map((col) => (
                <TableCell key={col} align="center">{col}</TableCell>
              ))}
              {props.actions && <TableCell align="center">ACTION</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) => (
              <TableRow key={index}>
                {Object.values(row).map((col, index) => <TableCell key={index} className={classes.row} align="center">{col || col === 0 ? col : '-'}</TableCell>)}
                {props.actions && <TableCell key={index} className={classes.row} align="center">
                  {/* {props.actions.view && <Tooltip title="Aperçu">
                    <span onClick={() => props.actions.view.action({ name: props.actions.edit.name[index], id: props.actions.edit.id[index] })}><FontAwesomeIcon icon={faSearch} className={classes.edit + ' ' + classes.icon} /></span>
                  </Tooltip>} */}
                  <Tooltip title="Éditer">
                    <span onClick={() => props.actions.edit.action({ name: props.actions.edit.name[index], id: props.actions.edit.id[index] })}><FontAwesomeIcon icon={faPencil} className={classes.edit + ' ' + classes.icon} /></span>
                  </Tooltip>
                  {/* <Tooltip title="Supprimer">
                    <span onClick={() => props.actions.delete.action({ id: props.actions.delete.id[index] })}><FontAwesomeIcon icon={faTrash} className={classes.icon} /></span>
                  </Tooltip> */}
                </TableCell>}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {
        props.pagination && <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={props.data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage='Lignes par page'
        />
      }
    </>
  );
};

export default TableList;
/* eslint-disable react-hooks/exhaustive-deps */
// 
// ─── IMPORT DES ELEMENTS NECESSAIRES ───────────────────────────────────────
//
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { createUseStyles } from 'react-jss';
import TableList from '../components/TableList';
import { useParams } from 'react-router-dom';
import Api from '../contexts/Api';

// 
// ─── DECLARATION DU COMPOSANT ───────────────────────────────────────
//
const UserList = () => {
  const pageParams = useParams();

  const { enqueueSnackbar } = useSnackbar();
  let history = useHistory();

  // 
  // ─── INITIALISATION DES STATS ───────────────────────────────────────
  //
  const [users, setUsers] = useState()
  const [modal, setModal] = useState({ open: false });
  const olwe_client_app_authenticate = JSON.parse(localStorage.getItem('olwe_client_app_authenticate'))

  // 
  // ─── RECUPERATION DU MENU DES STIES INTERNET ───────────────────────────────────────
  //
  useEffect(() => {
    const fetchData = async () => {
      const getUsers = await Api({ endpoint: `/users?username=${pageParams.username}&user_id=${olwe_client_app_authenticate.user_id}`, method: 'GET', token: true })
      if (getUsers.success) {
        setUsers(getUsers.content)
      }
    }
    if (!users) {
      fetchData()
    }
  }, [users])

  const handleClose = () => {
    setModal({ open: false });
  };

  const editUser = async (opt) => {
    history.push(`/${pageParams.username}/utilisateurs/edition/${opt.id}`)
  }

  const deleteUser = async (opt) => {
    if (opt.fromModal) {
      // 
      // ─── REQUETE API POUR DELETE UN UTILISATEUR ───────────────────────────────────────
      //
      let params = {
        data: JSON.stringify({
          username: 'olwin_dev',
        }),
        endpoint: `/users/${modal.id}`,
        method: 'DELETE',
      }

      const deleteUser = await Api(params)

      // 
      // ─── AFFICHAGE D'UNE ALERTE EN FONCTION DU RETOUR API ───────────────────────────────────────
      //
      enqueueSnackbar(deleteUser.message, { variant: deleteUser.success ? 'success' : 'error' })

      setModal({ open: false })

      // 
      // ─── RETIRER L'UTILISATEUR DE LA LISTE SI LE RETOUR API EST SUCCESS ───────────────────────────────────────
      //
      if (deleteUser.success) {
        setUsers(users.filter(value => value.user_id !== modal.id))
      }
    } else {
      setModal({ open: true, id: opt.id })
    }
  }

  let formatUsers = []
  let userId = []
  let userName = []

  users && users.length && users.forEach((value) => {
    formatUsers.push({
      name: value.name,
      firstname: value.firstname,
      email: value.email,
      phone: value.phone,
    })
    userId.push(value.user_id)
    userName.push(`${value.name}_${value.firstname}`)
  })

  const actions = {
    edit: {
      action: editUser,
      id: userId,
      name: userName
    },
    // delete: {
    //   action: deleteUser,
    //   id: userId
    // },
    // view: {
    //   action: viewUser,
    //   id: userId,
    //   name: userName
    // }
  }

  // 
  // ─── INITIALISATION DES STYLES ───────────────────────────────────────
  //
  const useStyle = createUseStyles({
    container: {
      display: 'flex',
      alignItems: 'center'
    },
    image: {
      width: 200,
      marginLeft: 20
    },
    title: {
      fontWeight: 'bold',
      color: 'var(--secondary-color)',
      padding: "20px 0 !important"
    },
    description: {
      paddingRight: 20
    },
    btnContainer: {
      marginTop: 10
    }
  })

  const classes = useStyle()

  // 
  // ─── RENDU DU COMPOSANT ───────────────────────────────────────
  //
  return (
    <>
      <h1>Edition de la page</h1>
      {formatUsers.length && <TableList data={formatUsers} header={["NOM", "PRÉNOM", "EMAIL", "TÉLÉPHONE"]} actions={actions} element_id={userId} />}
    </>
  );
};

export default UserList;
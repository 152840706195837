//
// ─── IMPORT DE TOUS LES COMPOSANTS NECESSAIRES AU SITES INTERNET ───────────────────────────────────────
//
import ilcortile_Menu from "../components/Clients/ilcortile/Menu";

//
// ─── DECLARATION DE TOUS LES COMPOSANTS DISPONIBLES POUR LES CLIENTS ───────────────────────────────────────
//
export const websiteComponents = [
  { component: ilcortile_Menu, label: "Menu Il Cortile", username: 'ilcortile' },
]

export const clientsLogo = {
  'dev': {
    logo: require('../assets/img/client_logo/logo-dev.png'),
    color: '#FFB100',
  },
  'ilcortile': {
    logo: require('../assets/img/client_logo/logo-ilcortile.png'),
    color: '#3C2636',
  }
}
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import App from './App';
import reportWebVitals from './reportWebVitals';
import IconButton from '@material-ui/core/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { createTheme, ThemeProvider } from '@mui/material/styles';

// 
// ─── MISE EN PLACE DES NOTIFICATIONS ───────────────────────────────────────
//
const notistackRef = React.createRef();
const onClickDismiss = (key) => {
  notistackRef.current.closeSnackbar(key);
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#6C9BF9',
    }
  },
});

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <Router>
      <SnackbarProvider
        ref={notistackRef}
        maxSnack={3}
        action={(key) => (
          <IconButton size="medium" onClick={() => { onClickDismiss(key) }}>
            <FontAwesomeIcon icon={faTimes} />
          </IconButton>
        )}
      >

        <App />
      </SnackbarProvider>
    </Router>
  </ThemeProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
// 
// ─── IMPORT DES ELEMENTS NECESSAIRES ───────────────────────────────────────
//
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Api from '../contexts/Api'
import UserManage from '../containers/UserManage/UserManage'

// 
// ─── DECLARATION DU COMPOSANT ───────────────────────────────────────
//
const UserEdit = () => {

  const userParams = useParams()
  // 
  // ─── INITIALISATION DES STATS ───────────────────────────────────────
  //
  const [user, setUser] = useState()

  useEffect(() => {
    const fetchData = async () => {
      const getUser = await Api({ endpoint: `/users?user_id=${userParams.user_id}&single=true`, method: 'GET', token: true })
      if (getUser.success) {
        setUser(getUser.content)
      }
    }
    if (!user) {
      fetchData()
    }
  }, [user, userParams])

  // 
  // ─── RENDU DU COMPOSANT ───────────────────────────────────────
  //
  return <>
    <h1>Editer un utilisateur</h1>
    {user && <UserManage defaults={user} username={userParams.username} update={true} />}
  </>
};

export default UserEdit;
/* eslint-disable react-hooks/exhaustive-deps */
// 
// ─── IMPORT ÉLÉMENTS NÉCESSAIRES ───────────────────────────────────────
//
import React, { Fragment, useEffect, useState } from 'react';
import Routes from './contexts/Routes';
import './assets/scss/main.scss';
import './assets/scss/react_material.scss';
import Api from './contexts/Api';
import { useLocation } from "react-router-dom";
import { getUsername } from './contexts/Functions';

// 
// ─── IMPORT DES COMPOSANTS ───────────────────────────────────────
//
// import Header from './components/Header';
// import Footer from './components/Footer';
import NavBar from './components/Navigation/Navigation';
import Login from './views/Login';

// 
// ─── DECLARATION DU COMPOSANT ───────────────────────────────────────
//
const App = () => {

  let location = useLocation()
  const username = getUsername()

  const [logUser, setLogUser] = useState(JSON.parse(localStorage.getItem('olwe_client_app_authenticate')));
  const [authenticated, setAuthenticated] = useState()

  const handleChange = async (event) => {
    event.preventDefault();
    event.persist();

    const params = {
      data: {
        "email": event.target.email.value,
        "password": event.target.password.value,
        username
      },
      endpoint: '/oauth/login',
    }

    const fetchToken = await Api(params)
    if (fetchToken.success && fetchToken.access_token) {
      setLogUser({ token: fetchToken.access_token, user_id: fetchToken.user_id, username: fetchToken.username })
      localStorage.setItem('olwe_client_app_authenticate', JSON.stringify({
        token: fetchToken.access_token,
        user_id: fetchToken.user_id,
      }));
    }
  }


  useEffect(() => {
    // 
    // ─── VERIFICATION SI L'UTILISATEUR EST BIEN AUTHENTIFIE ───────────────────────────────────────
    //
    const fetchData = async () => {
      const params = {
        data: {
          "user_id": logUser.user_id,
          "username": username
        },
        endpoint: '/oauth/authenticate',
        token: true
      }

      const isAuthenticated = await Api(params)

      if (isAuthenticated && isAuthenticated.success) {
        setAuthenticated(true)
      } else {
        setAuthenticated(false)
      }
    }

    // 
    // ─── SI LE STATE EST SET, ON LANCE LA FONCTION ───────────────────────────────────────
    //
    if (location.pathname !== '/') {
      if (logUser) {
        fetchData()
      } else {
        setAuthenticated(false)
      }
    }
  }, [logUser, location])

  // 
  // ─── RENDU DU COMPOSANT ───────────────────────────────────────
  //
  return (
    <>
      {location.pathname === '/' ?
        <div></div>
        :
        authenticated === false ? (
          <Login handleChange={handleChange} username={username} />
        ) : authenticated ? (
          <Fragment>
            <NavBar />
            <main>
              <Routes />
            </main>
          </Fragment>
        ) : <span>Loader</span>
      }

    </>
  );
}

export default App;
const Api = (opt) => {
  // 
  // ─── CREATION DU HEADER NECESSAIRE A LA REQUETE ───────────────────────────────────────
  //
  let myHeaders = new Headers();
  if (!opt.removeHeader) {
    myHeaders.append("Content-Type", "application/json");
  }

  // 
  // ─── AJOUT DU TOKEN DANS LE HEADER S'IL EST RENSEIGNE ───────────────────────────────────────
  //
  if (opt.token) {
    myHeaders.append('Authorization', `Bearer ${JSON.parse(localStorage.getItem('olwe_client_app_authenticate')).token || ''}`);
  }

  // 
  // ─── DEFINITION DE LA METHODE A UTILISER ───────────────────────────────────────
  //
  let method = opt.method || 'POST'

  // 
  // ─── AJOUT DES OPTIONS DANS LE HEADER ───────────────────────────────────────
  //
  let requestOptions = {
    method,
    headers: myHeaders,
    redirect: 'follow'
  }

  // 
  // ─── AJOUT DES DONNEES SOUS FORME DE JSON STRINGIFY ───────────────────────────────────────
  //
  if (['POST', 'PUT', 'DELETE'].includes(method)) {
    requestOptions = { ...requestOptions, body: JSON.stringify(opt.data) }
  }

  const devServerUrl = window.location.href.includes('app.olwe.fr') ? 'client_app' : 'dev_client_app';

  // 
  // ─── REQUETE A L'API ───────────────────────────────────────
  //
  return fetch(`https://api.olwe.fr/${devServerUrl}${opt.endpoint}`, requestOptions)
    .then(response => response.json())
    .then(result => result)
    .catch(error => console.log('error', error));
}

export default Api;
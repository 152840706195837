// 
// ─── IMPORT DES ELEMENTS NECESSAIRES ───────────────────────────────────────
//
import React from "react";
import { Route, Switch } from 'react-router-dom';

// 
// ─── IMPORT DES COMPOSANTS ───────────────────────────────────────
//
import Home from '../views/Home';
import UserList from '../views/UserList';
import UserEdit from '../views/UserEdit';
import NoMatch from '../views/NoMatch';

// 
// ─── DECLARATION DU COMPOSANT ───────────────────────────────────────
//
const Routes = () => {
  // 
  // ─── DECLARATION DE TOUTES LES ROUTES NECESSAIRES ───────────────────────────────────────
  //
  return (
    <>
      <Switch>
        <Route exact path="/:username" component={Home} />
        <Route exact path="/:username/utilisateurs" component={UserList} />
        <Route exact path="/:username/utilisateurs/edition/:user_id" component={UserEdit} />
        <Route component={NoMatch} />
      </Switch>
    </>
  );
};

export default Routes
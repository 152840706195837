// 
// ─── IMPORT DES ELEMENTS NECESSAIRES ───────────────────────────────────────
//
import React, { useState } from 'react';
import Formfields from "../../components/Formfields";
import Button from '@mui/material/Button';
import { getValue, prepareFormData } from '../../contexts/Functions';
import { useSnackbar } from 'notistack';
import Api from '../../contexts/Api';

// 
// ─── DECLARATION DU COMPOSANT ───────────────────────────────────────
//
const UserManage = (props) => {

  const { enqueueSnackbar } = useSnackbar();

  const userDefaults = props.defaults || {}

  const userFields = [
    {
      fields: [
        { type: "text", component: "text", name: "name", label: "Nom", data_attribute: 'required', default: userDefaults.name },
        { type: "text", component: "text", name: "firstname", label: "Prénom", data_attribute: 'required', default: userDefaults.firstname },
        { type: "phone", component: "text", name: "phone", label: "Téléphone", data_attribute: 'required', default: userDefaults.phone },
        { type: "email", component: "text", name: "email", label: "email", data_attribute: 'required', default: userDefaults.email },
        { type: "password", component: "text", name: "password", label: "Mot de passe", data_attribute: 'required', cond: !props.update },
        { type: "password", component: "text", name: "password_repeat", label: "Saisissez à nouveau le mot de passe", data_attribute: 'required', cond: !props.update },
      ]
    },
    {
      fields: [
        { type: "password", component: "text", name: "password_old", label: "Mot de passe actuel", data_attribute: 'required' },
        { type: "password", component: "text", name: "password", label: "Nouveau mot de passe", data_attribute: 'required' },
        { type: "password", component: "text", name: "password_repeat", label: "Saisissez à nouveau le mot de passe", data_attribute: 'required' },
      ],
      button: { label: 'Modifier le mot de passe' },
      type: 'password',
      cond: props.update
    }
  ]

  const [errors, setErrors] = useState()

  // 
  // ─── TRAITEMENT DU FORMULAIRE ───────────────────────────────────────
  //
  const handleFormSubmit = async (type) => {
    let data = {
      username: props.username,
      user_id: userDefaults.user_id
    }

    data = { ...data, ...prepareFormData({ formId: type === 'password' ? 'myForm-password' : 'myForm' }) }


    let password = getValue(data, ['fields', 'password'])
    let password_repeat = getValue(data, ['fields', 'password_repeat'])

    if (password === password_repeat) {
      delete data.fields.password_repeat
    } else {
      data.errors = {
        ...data.errors,
        password: true,
        password_repeat: true
      }
    }

    if (data.errors) {
      setErrors(data.errors)
    } else {
      setErrors({})

      if (type === 'password') {
        data.update_password = true
      }

      let params = {
        data,
        endpoint: props.update ? `/users/${userDefaults.user_id}` : '/users',
        method: props.update ? 'PUT' : 'POST',
        token: true
      }
      const manageUsers = await Api(params)

      // 
      // ─── AFFICHAGE D'UNE ALERTE EN FONCTION DU RETOUR API ───────────────────────────────────────
      //
      enqueueSnackbar(manageUsers.message, { variant: manageUsers.success ? 'success' : 'error' })
    }
  }

  // 
  // ─── RENDU DU COMPOSANT ───────────────────────────────────────
  //
  return <>
    {userFields.map((element, index) => {
      return <form className='form product-form' name={element.type === 'password' ? 'myForm-password' : "myForm"} key={index}>
        {((element.hasOwnProperty('cond') && element.cond) || !element.hasOwnProperty('cond')) && <div className='form-container__card'>
          {element.fields.map((el) => {
            if (el.hasOwnProperty('cond') && !el.cond) {
              return true
            }
            if (errors) el.error = errors[el.name]
            return <Formfields key={el.name} field={el} />
          })}
          <Button variant="contained" onClick={() => { handleFormSubmit(element.type) }}>{getValue(element, ['button', 'label']) || 'Enregistrer'}</Button>
        </div>}
      </form>
    })}
  </>
};

export default UserManage;
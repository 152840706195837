// 
// ─── GET VALUE FROM OBJECT ───────────────────────────────────────
//
export const getValue = (object, values) => {
  if (!object) {
    return undefined
  }

  let objectValue = object

  values.map((value) => {
    if (objectValue && objectValue.hasOwnProperty(value)) {
      objectValue = objectValue[value]
    } else {
      objectValue = undefined
    }
    return objectValue
  })

  return objectValue
}

// 
// ─── PREPARE FORM DATA BEFORE SUBMIT  ───────────────────────────────────────
//
export const prepareFormData = (opt) => {
  let data = {}
  let errors = {}
  let multipleDesc = {}

  Array.from(document.forms[opt.formId]).filter(e => e.getAttribute("name")).forEach((element) => {
    let fieldValue
    if (element.type === 'radio') {
      if (element.checked) {
        fieldValue = { [element.name]: element.value }
      }
    } else if (element.dataset.attribute !== 'multiple') {
      if (element.dataset.attribute === 'required' && !element.value) {
        errors[element.name] = true
      } else if (element.value) {
        fieldValue = { [element.name]: element.value }
      }
      // Traitement spécifique pour les formulaires multiples
    } else if (element.dataset.attribute === 'multiple' && element.value) {
      const elementId = parseInt(element.name.slice(-1))
      multipleDesc[elementId] = {
        ...multipleDesc[elementId],
        ...{
          [element.name]: element.value
        }
      }
    }

    data.fields = {
      ...data.fields, ...fieldValue
    }

    return data
  })

  if (opt.multiple) {
    data.fields[opt.multiple] = JSON.stringify(multipleDesc)
  }

  if (Object.keys(errors).length) {
    data.errors = errors
  }

  return data
}

// 
// ─── UPDATE OBJECT VALUE ───────────────────────────────────────
//
export const updateObject = (object, newValue, path) => {
  var stack = path.split('.');
  while (stack.length > 1) {
    object = object[stack.shift()];
  }
  object[stack.shift()] = newValue;
  return object
}

// 
// ─── RÉCUPÉRATION DU USERNAME DU CLIENT ───────────────────────────────────────
//
export const getUsername = () => {
  const username = window.location.pathname.split('/').filter(v => v)[0]
  return username
}
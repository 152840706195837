/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { createUseStyles } from 'react-jss'
import { getValue } from '../contexts/Functions'

import TextField from '@mui/material/TextField';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Button from '@mui/material/Button';

// 
// ─── TEXTFIELD ───────────────────────────────────────
//
export const Text = (opt) => {

  const field = opt.field;
  const defaultValue = getValue(field, ['default']) || field.value
  const dataElement = getValue(field, ['data_attribute']) ? { 'data-attribute': field.data_attribute } : {}

  return (
    <TextField
      id="outlined-multiline-flexible"
      multiline={field.multiline}
      rows={field.rows ? 6 : 1}
      label={field.label}
      hidden={field.hidden}
      type={field.type}
      name={field.name}
      defaultValue={defaultValue}
      inputProps={{
        ...dataElement
      }}
      error={field.error}
      className={field.style}
    />
  )
}

// 
// ─── TEXTFIELD ───────────────────────────────────────
//
export const Textarea = (opt) => {

  const field = opt.field;
  const defaultValue = getValue(field, ['default']) || field.value

  return (
    <TextareaAutosize
      aria-label="empty textarea"
      placeholder="Empty"
      name={field.name}
      defaultValue={defaultValue}
      className={field.style}
    />
  )
}

// 
// ─── SWITCH ───────────────────────────────────────
//
export const SwitchField = (opt) => {

  const field = opt.field
  const defaultValue = getValue(field, ['default']) !== undefined ? getValue(field, ['default']) : field.value || 0

  // 
  // ─── STATE DECLARATION ───────────────────────────────────────
  //
  const [selected, setSelected] = useState(defaultValue)

  return (
    <>
      <FormControlLabel
        control={
          <Switch checked={selected ? true : false} value={selected} onChange={() => { setSelected(selected === 0 ? 1 : 0) }} name={field.name} />
        }
        label={field.label}
      />
    </>
  )
}

// 
// ─── SELECT ───────────────────────────────────────
//
export const SelectField = (opt) => {
  const field = opt.field
  const defaults = getValue(field, ['default'])

  const [select, selectValue] = useState(defaults);

  const handleChange = (event) => {
    selectValue(event.target.value);
  };

  return (
    <FormControl variant="outlined">
      <InputLabel id="simple-select-label" shrink>{field.label}</InputLabel>
      <Select
        value={select}
        input={
          <OutlinedInput
            notched
            name={field.name}
            id="simple-select-label"
            label={field.label}
          />
        }
        onChange={handleChange}
      >
        {field.data.map((value) => (
          <MenuItem key={value[field.dataValue]} value={value[field.dataValue]}>{value[field.dataLabel]}</MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

// 
// ─── CHECKBOX ───────────────────────────────────────
//
export const Checkbox = (opt) => {

  const field = opt.field

  // 
  // ─── STATE DECLARATION ───────────────────────────────────────
  //
  const [checked, setChecked] = useState(field.checked)

  // 
  // ─── HANDLE STATE CHANGE ON CHECKED : USED BECAUSE WE CAN HAVE TWICE THE SAME BUT WITH DIFFERENT VALUES ───────────────────────────────────────
  //
  useEffect(() => {
    if (field.checked !== checked) {
      setChecked(field.checked)
    }
  }, [field.checked])

  return (
    <>
      <label htmlFor={field.name}>{field.label}</label>
      <input type={field.type} name={field.name} value={field.value} checked={checked} disabled={field.disabled} onChange={() => { setChecked(!checked) }} />
    </>
  )
}

// 
// ─── MULTIPLE ───────────────────────────────────────
//
export const Multiple = (opt) => {
  const field = opt.field
  const fieldName = field.name
  const titles = field.titles
  const defaults = getValue(field, ['default'])

  // 
  // ─── CHAMPS NECESSAIRES ───────────────────────────────────────
  //
  let multipleFields = {}

  if (defaults && defaults.length) {
    defaults.forEach((value, index) => {
      const elementId = `${opt.slug}_${index + 1}`
      multipleFields[elementId] = [
        { type: "text", component: "text", label: titles.label, value: value[`${opt.slug}_label_${index + 1}`], name: `${opt.slug}_label_${index + 1}` },
        { type: "text", component: "text", label: titles.value, value: value[`${opt.slug}_value_${index + 1}`], name: `${opt.slug}_value_${index + 1}`, multiline: field.multiline, hidden: field.hidden }
      ]
    })
  } else {
    multipleFields = {
      1: [
        { type: "text", component: "text", label: titles.label, name: `${fieldName}_label_1` },
        { type: "text", component: "text", label: titles.value, name: `${fieldName}_value_1`, multiline: field.multiline, hidden: field.hidden }
      ]
    }
  }

  // 
  // ─── STATE DECLARATION ───────────────────────────────────────
  //
  const [fields, setFields] = useState(multipleFields)

  // 
  // ─── INITIALISATION DES STYLES ───────────────────────────────────────
  //
  const useStyle = createUseStyles({
    multipleContainer: {
      display: 'flex',
      flexDirection: 'column'
    },
    multipleLabel: {
      display: 'flex',
      '& > span': {
        width: '50%'
      }
    },
    multipleFields: {
      display: 'flex'
    },
    button: {
      marginTop: "20px !important"
    },
    inputLeft: {
      marginLeft: 5
    },
    inputRight: {
      marginRight: 5
    }
  })

  const classes = useStyle();

  return (
    <div>
      <label>{field.label}</label>
      <div className={classes.multipleContainer}>
        {Object.values(fields).map((element, index) => {
          return <div key={index} className={classes.multipleFields}>
            {
              element.map((el, i) => {
                return <Formfields key={i} field={{ ...el, ...{ data_attribute: 'multiple' } }} classNameOuter={i === 0 ? classes.inputRight : classes.inputLeft} />
              })
            }
          </div>
        })}
      </div>
      <Button className={classes.button} variant="contained" onClick={(event) => {
        event.preventDefault();
        let index = Object.keys(fields).length + 1;

        // 
        // ─── AJOUT D'UN ELEMENT SUPPLEMENTAIRE A LA LISTE ───────────────────────────────────────
        //
        setFields(fields[index] = {
          ...fields,
          [`${opt.slug}_${index}`]: [
            { type: "text", component: "text", label: titles.label, name: `${fieldName}_label_${index}` },
            { type: "text", component: "text", label: titles.value, name: `${fieldName}_value_${index}`, multiline: field.multiline, hidden: field.hidden }
          ]
        })
      }}>Ajouter</Button>
    </div>
  )
}

// 
// ─── GENERATION DES CHAMPS ───────────────────────────────────────
//
const Formfields = (props) => {
  let fields

  switch (props.field.component) {
    case "switch":
      fields = SwitchField(props)
      break;
    case "select":
      fields = SelectField(props)
      break;
    case "checkbox":
      fields = Checkbox(props)
      break;
    case "multiple":
      fields = Multiple(props)
      break;
    case "textarea":
      fields = Textarea(props)
      break;
    default:
      fields = Text(props)
      break;
  }

  // 
  // ─── INITIALISATION DES STYLES ───────────────────────────────────────
  //
  const useStyle = createUseStyles({
    inputContainer: {
      display: "flex",
      flexDirection: "column",
      width: '100%',
    },
  })

  const classes = useStyle();

  return <div className={classes.inputContainer + ' ' + props.classNameOuter}>
    {fields}
  </div>
}

export default Formfields
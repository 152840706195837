// 
// ─── IMPORT DES ELEMENTS NECESSAIRES ───────────────────────────────────────
//
import React, { Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import menu from '../../configs/menu.json'
import './Navigation.scss'
import { getUsername } from '../../contexts/Functions';

// 
// ─── IMPORT DES ELEMENTS FONTAWESOME ───────────────────────────────────────
//
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faBoxFull, faTruck, faChartPie, faCogs, faCopy, faUsers, faBuilding, faUser, faPuzzlePiece } from '@fortawesome/pro-duotone-svg-icons'
import { faAngleDown } from '@fortawesome/pro-regular-svg-icons'


// 
// ─── DECLARATION DU COMPOSANT ───────────────────────────────────────
//
const NavBar = () => {
  const username = getUsername();

  // 
  // ─── AJOUT DES ICONES NECESSAIRES ───────────────────────────────────────
  //
  const menuIcons = {
    'faHome': faHome,
    'faTruck': faTruck,
    'faChartPie': faChartPie,
    'faBoxFull': faBoxFull,
    'faCogs': faCogs,
    'faCopy': faCopy,
    'faUsers': faUsers,
    'faBuilding': faBuilding,
    'faUser': faUser,
    'faPuzzlePiece': faPuzzlePiece,
  }

  // 
  // ─── TOGGLE ACCORDION ───────────────────────────────────────
  //
  const dropDownToggle = (obj) => {
    let accordion = obj.target.nextSibling;
    if (accordion.classList.contains('accordion-open')) {
      accordion.style.height = "0px"
    } else {
      const elementNumber = Array.from(accordion.children)
      accordion.style.height = `${(elementNumber.length * (19 + 10) + 16)}px`;
    }
    accordion.classList.toggle('accordion-open')
  }

  // 
  // ─── GENERATE MENU RECURSIVELY ───────────────────────────────────────
  //
  const generateMenu = (opt) => {
    return opt.data.map((value) => {

      if (value.online === false) return true

      return <Fragment key={value.label}>
        {
          // 
          // ─── SET THE CATEGORY TITLE ───────────────────────────────────────
          //
        }
        {value.title ? (
          <>
            {value.label && <div>{value.label}</div>}
            <ul>
              {generateMenu({ data: value.children, path: [...opt.path, value.label] })}
            </ul>
          </>
        ) : (
          // 
          // ─── SET THE NAVIGATION ELEMENTS ───────────────────────────────────────
          //
          <li key={value.label}>
            {value.hasOwnProperty('icon') && !value.hasOwnProperty('href') && value.children && value.children.length ?
              <div onClick={(e) => { dropDownToggle(e) }} className='menu-parent'>
                <FontAwesomeIcon icon={menuIcons[value.icon]} className="menu-icon" />
                <span>{value.label}</span>
                <FontAwesomeIcon icon={faAngleDown} />
              </div>
              : <NavLink to={value.href.replace('{{username}}', username)} exact activeClassName="menu-link-selected" className={(!value.href ? "disable-link" : '') + ' ' + (value.hasOwnProperty('icon') ? 'menu-parent' : '')}>
                {value.hasOwnProperty('icon') && <FontAwesomeIcon icon={menuIcons[value.icon]} className="menu-icon" />}
                <span>{value.label}</span>
              </NavLink>}

            {Boolean(value.children.length) && value.children.length && (
              <ul className='accordion'>
                {generateMenu({ data: value.children, path: [...opt.path, value.label] })}
              </ul>
            )}
          </li>
        )}
      </Fragment>
    })
  }

  const toggleMenu = (obj) => {
    document.querySelector('.burger').classList.toggle('clicked');
    document.querySelector('.menu-wrapper').classList.toggle('menu-wrapper-open')
  }

  // 
  // ─── RENDU DU COMPOSANT ───────────────────────────────────────
  //
  return (
    <div className='menu-wrapper'>

      <div class="burger" onClick={toggleMenu} >
        <span></span>
      </div>
      <nav className='app-navigation'>
        <div className="menu-container">
          {generateMenu({ data: menu, path: [] })}
        </div>
      </nav >
    </div>
  );
};

export default NavBar;
// 
// ─── IMPORT DES ELEMENTS NECESSAIRES ───────────────────────────────────────
//
import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss'
import { useSnackbar } from 'notistack';
import Button from '@mui/material/Button';
import Formfields from '../../Formfields';
import { prepareFormData, getValue, updateObject } from "../../../contexts/Functions"
import Api from "../../../contexts/Api"

// 
// ─── IMPORT DES ELEMENTS FONTAWESOME ───────────────────────────────────────
//
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/pro-duotone-svg-icons'

// 
// ─── DECLARATION DU COMPOSANT ───────────────────────────────────────
//
const Menu = (props) => {
  const [menuData, setMenuData] = useState({})
  const [checkDisable, setCheckDisable] = useState({})
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const fetchData = async () => {
      const params = {
        endpoint: `/ilcortile/menu?username=${props.username}&user_id=${props.user_id}`,
        method: 'GET',
        token: true
      }

      const getMenu = await Api(params)
      if (getMenu.success) {
        setMenuData(getMenu.content)
      }
    }

    if (!Object.keys(menuData).length) {
      fetchData()
    }
  })

  // 
  // ─── AJOUT DES ICONES NECESSAIRES ───────────────────────────────────────
  //
  const icons = {
    'faEye': faEye,
    'faEyeSlash': faEyeSlash,
  }

  // 
  // ─── INITIALISATION DES STYLES ───────────────────────────────────────
  //
  const useStyle = createUseStyles({
    wrapper: {
      marginBottom: 50
    },
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    menuContainer: {
      background: '#FFF',
      padding: 10,
      marginBottom: 20,
      boxShadow: '1px 1px 6px 0px rgb(0, 0, 0,0.1)',
      width: 'calc(33% - 20px)',
      '@media screen and (min-width:1200px)': {
        width: 'calc(33% - 60px)',
        margin: 10,
        padding: 20,
      },
      '@media screen and (max-width:1200px)': {
        width: '100%'
      }
    },
    input: {
      minHeight: '25px',
      width: '100%',
      fontFamily: 'Poppins, sans-serif',
      border: 'none',
      background: 'transparent',
      marginBottom: 10
    },
    inputTitle: {
      fontWeight: 'bold',
      fontSize: 14
    },
    conditionTitle: {
      fontSize: 14,
      color: "#3C2636"
    },
    button: {
      position: 'fixed !important',
      left: '50%',
      bottom: 20,
      transform: 'translateX(-50%)'
    },
    disableContainer: {
      display: 'flex',
      alignItems: 'center'
    },
    disable: {
      cursor: 'pointer',
      marginBottom: 10
    },
    iconDisabled: {
      color: 'red'
    },
    iconEnabled: {
      color: 'green'
    }
  })

  const classes = useStyle();

  const defaultLabel = {
    menu_cortile: 'Menu Il Cortile',
    menu_cortile_event: 'Menu Il Cortile - Évènement',
    menu_carte: 'La carte',
    menu_plats: 'Les plats'
  }

  // 
  // ─── FORMATAGE DU MENU ───────────────────────────────────────
  //
  const showMenu = (opt) => {
    let path = opt.path || ''

    return Object.entries(opt.data).map(([key, menu]) => {
      path = opt.path ? opt.path + '.' + key : key;
      let isDisabled = false

      if (checkDisable.hasOwnProperty(`${path}.disable`)) {
        isDisabled = Boolean(checkDisable[`${path}.disable`])
      } else if (menu.hasOwnProperty('disable')) {
        isDisabled = Boolean(menu.disable)
      }

      return <div className={opt.level === 0 && classes.menuContainer}>
        {opt.level === 0 && <h1>{defaultLabel[key]}</h1>}
        {
          menu.title ?
            <div>
              <div className={classes.disableContainer}>
                <input type='text' name={`${path}.title`} defaultValue={menu.title} className={[classes.input, classes.inputTitle].join(' ')} />
                {menu.hasOwnProperty('disable') && <FontAwesomeIcon icon={isDisabled ? icons.faEyeSlash : icons.faEye} className={[classes.disable, isDisabled ? classes.iconDisabled : classes.iconEnabled].join(' ')} onClick={() => { handleDisable({ path: key }) }} />}
              </div>
              {menu.elements && menu.elements.map((plats, index) => {
                const temp_path = `${path}.elements.${index}`
                return <div>
                  <Formfields field={{ type: "text", component: "textarea", style: classes.input, name: `${temp_path}.label`, value: plats.label }} />
                  {plats.price && <input type='text' name={`${temp_path}.price`} defaultValue={plats.price} className={classes.input} />}
                </div>
              })}
              {Boolean(menu.condition && menu.condition.length) &&
                <>
                  <h3 className={classes.conditionTitle}>Conditions de la table</h3>
                  {menu.condition.map((cond, index) => {
                    const temp_path = `${path}.condition.${index}`
                    return <div>
                      <Formfields field={{ type: "text", component: "textarea", style: classes.input, name: temp_path, value: cond }} />
                    </div>
                  })}
                </>}
              {menu.price && <input type='text' name={`${path}.price`} defaultValue={menu.price} className={classes.input} />}
            </div >
            :
            showMenu({ data: menu, path })
        }
      </div >
    })
  }

  // 
  // ─── MODIFICATION DU MENU ───────────────────────────────────────
  //
  const handleChange = async (e) => {
    e.preventDefault();
    // 
    // ─── RECUPERATION DES VALEURS DES CHAMPS ───────────────────────────────────────
    //
    const formData = prepareFormData({ formId: 'myForm' })
    Object.entries(formData.fields).map(([key, value]) => {
      const checkValueExist = getValue(menuData, key.split('.'))
      if (checkValueExist) {
        // 
        // ─── AJOUT DES VALEURS DANS L'OBJET ───────────────────────────────────────
        //
        updateObject(menuData, value, key)
      }
      return checkValueExist
    })

    const params = {
      data: { menu: menuData, username: props.username, user_id: props.user_id },
      token: true,
      endpoint: '/ilcortile/menu'
    }

    const saveMenu = await Api(params)
    // 
    // ─── AFFICHAGE D'UNE ALERTE EN FONCTION DU RETOUR API ───────────────────────────────────────
    //
    enqueueSnackbar(saveMenu.message, { variant: saveMenu.success ? 'success' : 'error' })
  }

  const handleDisable = async (opt) => {
    const name = `${opt.path}.disable`;
    const checkValueExist = getValue(menuData, name.split('.'));
    const disableValue = checkValueExist ? 0 : 1;

    // 
    // ─── AJOUT DES VALEURS DANS L'OBJET ───────────────────────────────────────
    //
    updateObject(menuData, disableValue, name)
    setCheckDisable({ [name]: disableValue })

    const params = {
      data: { menu: menuData, username: props.username, user_id: props.user_id },
      token: true,
      endpoint: '/ilcortile/menu'
    }

    const saveMenu = await Api(params)
    // 
    // ─── AFFICHAGE D'UNE ALERTE EN FONCTION DU RETOUR API ───────────────────────────────────────
    //
    enqueueSnackbar(saveMenu.message, { variant: saveMenu.success ? 'success' : 'error' })
  }

  // 
  // ─── RENDU DU COMPOSANT ───────────────────────────────────────
  //
  return (
    <div className={classes.wrapper}>
      <form className='form' id='BookPackageForm' name="myForm" onSubmit={e => { e.preventDefault(); }}>
        <div className={classes.container}>
          {showMenu({ data: menuData, level: 0 })}
        </div>
      </form>
      <Button className={classes.button} onClick={(e) => handleChange(e)} variant="contained">Enregistrer</Button>
    </div>
  );
};

export default Menu;
import React from 'react';
import { createUseStyles } from 'react-jss';
import { clientsLogo } from '../contexts/Contexts';
import { getValue } from '../contexts/Functions';

const Login = ({ handleChange, username }) => {

  const clientColor = getValue(clientsLogo, [username, 'color'])

  // 
  // ─── DEFINITION DES STYLES DU COMPOSANT ───────────────────────────────────────
  //
  const useStyle = createUseStyles({
    wrapper: {
      height: '100vh',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    container: {
      maxWidth: '400px',
      width: '100%',
      background: '#FFF',
      padding: 20,
      boxShadow: "4px 4px 20px 0 rgba(0, 0, 0, 0.2)"
    },
    logo: {
      height: 55,
      display: 'block',
      margin: 'auto auto 10px',
    },
    formLogin: {
      display: 'flex',
      flexDirection: 'column',
    },
    label: {
      margin: '20px 0 0 0',
    },
    fields: {
      height: 40,
      paddingLeft: 10,
      background: '#FCFDFE',
      border: 'solid 1px #E6E9EC'
    },
    submitField: {
      marginTop: 20,
      height: 40,
      background: clientColor,
      border: 'none',
      color: 'white',
      fontWeight: 'bold',
      fontSize: '15px',
      cursor: 'pointer',
      border: `2px solid ${clientColor}`,
      '&:hover': {
        border: `2px solid ${clientColor}`,
        background: 'transparent',
        color: clientColor
      }
    }
  })

  // 
  // ─── RECUPERATION DES STYLES ───────────────────────────────────────
  //
  const classes = useStyle()

  // 
  // ─── COMPOSANT LOGIN ───────────────────────────────────────
  //
  return (
    <div className={classes.wrapper}>
      <div className={classes.container}>
        <img src={getValue(clientsLogo, [username, 'logo'])} alt={`logo ${username}`} className={classes.logo} />
        <form onSubmit={handleChange} className={classes.formLogin}>
          <label htmlFor='email' className={classes.label}>Email</label>
          <input id='email' type='email' name='email' className={classes.fields} />
          <label htmlFor='password' className={classes.label}>Mot de passe</label>
          <input id="password" type='password' name='password' className={classes.fields} />
          <input type='submit' name='submit' value='Connexion' className={classes.submitField} />
        </form>
      </div>
    </div>
  )
}

export default Login;
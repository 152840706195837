/* eslint-disable array-callback-return */
// 
// ─── IMPORT DES ELEMENTS NECESSAIRES ───────────────────────────────────────
//
import React from 'react';
import { websiteComponents } from '../contexts/Contexts'
import { useParams } from 'react-router-dom';

// 
// ─── DECLARATION DU COMPOSANT ───────────────────────────────────────
//
const Home = () => {
  const pageParams = useParams();

  // 
  // ─── RENDU DU COMPOSANT ───────────────────────────────────────
  //
  return (
    <div>
      {websiteComponents.map((element) => {
        if (pageParams.username === element.username || pageParams.username === 'dev') {
          const olwe_client_app_authenticate = JSON.parse(localStorage.getItem('olwe_client_app_authenticate'))
          const Component = element.component;
          return <Component key={element.label} username={pageParams.username} user_id={olwe_client_app_authenticate.user_id} />
        }
      })}
    </div>
  );
};

export default Home;
// 
// ─── IMPORT DES ELEMENTS NECESSAIRES ───────────────────────────────────────
//
import React from 'react';

// 
// ─── DECLARATION DU COMPOSANT ───────────────────────────────────────
//
const NoMatch = () => {
  // 
  // ─── RENDU DU COMPOSANT ───────────────────────────────────────
  //
  return (
    <h1>Route not Found</h1>
  );
};

export default NoMatch;